import crossFetch from 'cross-fetch';

function fetchRequest(url, options, callback) {
    url = url.replace('https://cdn.api.theachievery.com', 'https://api.clc.theachievery.com');
    var fetchOptions = {
        headers: {
            Accept: 'application/json',
        },
    };

    if (options && options.proxyAgent) {
        fetchOptions.agent = options.proxyAgent;
    }
    // can't use number because of NodeJS globals included
    var timeoutId;
    var fetchPromise = crossFetch(url, fetchOptions);
    var promise = options.timeoutInMs
        ? Promise.race([
            fetchPromise,
            new Promise(function (_, reject) {
                timeoutId = setTimeout(function () {
                    return reject(new Error(url + ' response timeout'));
                }, options.timeoutInMs);
            }),
        ])
        : fetchPromise;
    promise
        .then(function (resp) {
            clearTimeout(timeoutId);
            if (~~(resp.status / 100 !== 2)) {
                /**
                 * @description
                 * drain the resp before throwing an error to prevent memory leaks
                 * @link https://github.com/bitinn/node-fetch/issues/83
                 */
                return resp.text().then(function () {
                    var e = new Error(
                        'Unexpected status code [' + resp.status + '] on URL ' + url
                    );
                    e.status = resp.status;
                    throw e;
                });
            }
            return resp.json().then(function (result) {
                var cacheControl = resp.headers.get('cache-control');
                var parsedCacheControl = cacheControl
                    ? /max-age=(\d+)/.exec(cacheControl)
                    : null;
                var ttl = parsedCacheControl
                    ? parseInt(parsedCacheControl[1], 10)
                    : undefined;
                callback(null, result, resp, ttl);
            });
        })
        .catch(function (err) {
            clearTimeout(timeoutId);
            callback(err);
        });
}

var DefaultRequestHandler = /** @class */ (function() {
  function DefaultRequestHandler(options) {
    this.options = options || {};
  }
  DefaultRequestHandler.prototype.request = function(url, callback) {
    fetchRequest(url, this.options, callback);
  };
  return DefaultRequestHandler;
})();

export { DefaultRequestHandler };
