import Vue from "vue";
import App from "./App.vue";
import PrismicVue from "@prismicio/vue";
import router from "./router";
import { DefaultRequestHandler } from "./handlers/prismicRequestHandler";

const prismicEndpoint = `${process.env.VUE_APP_CDN_API_BASE_PATH}/v1/content/prismic`;
var requestHandler = new DefaultRequestHandler();

Vue.use(PrismicVue, {
  endpoint: prismicEndpoint,
  apiOptions: { requestHandler },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
