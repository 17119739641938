import Vue from "vue";
import VueRouter from "vue-router";
import CLCHomePage from "@/components/CLCHomePage";// Importa tus componentes

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: CLCHomePage,
  },
  {
    path: "/en/",
    component: CLCHomePage,
  },
  {
    path: "/es/",
    component: CLCHomePage,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
