<template>
  <div id="app">
    <CLCHomePage msg="CLCs The Achievery"/>
  </div>
</template>

<script>
import CLCHomePage from './components/CLCHomePage.vue'

export default {
  name: 'App',
  components: {
    CLCHomePage
  },
}
</script>