/**
 * Tipo de documento utilizado en Prismic queries.
 * @type {string}
 */
const DOCUMENT_TYPE = "document.type";

/**
 * Tipo de documento para la homepage en Prismic.
 * @type {string}
 */
const CLC_HOMEPAGE = "clc_homepage";

/**
 * Tipo de documento para las noticias en Prismic.
 * @type {string}
 */
const CLC_NEWS = "clc_news";

/**
 * Parámetro para centros en URL query strings.
 * @type {string}
 */
const CENTER = "center";

/**
 * Parámetro para GLOBAL query strings.
 * @type {string}
 */
const GLOBAL = "global";


export default {
  DOCUMENT_TYPE,
  CLC_HOMEPAGE,
  CLC_NEWS,
  CENTER,
  GLOBAL,

};
