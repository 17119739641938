<template>

  <section data-component-name="clc-prismic" :class="{ 'full-layout': newsData && newsDataHasItems, 'ribbon-is-active': cintilloData.isAvailable }">

    <!-- Hero -->
    <figure>
      <div class="wrapper">
        <img alt="Connected Learning Centers" title="Connected Learning Centers" src="../assets/images/att-connected-learning-center.png" />
        <a class="change-lang" href="#" @click.prevent="toggleLanguage">
          {{ prismicLang === 'en-us' ? "Cambiar a Español" : 'Switch to English' }}
        </a>
      </div>
    </figure>

    <!-- Panel Link -->
    <article class="panel-link" :data-lang="prismicLang">
      <h1 v-html="bannerData.title"></h1>
      <ul v-if="bannerData.icons" class="ul-panel-link">
        <li v-for="(icon, index) in bannerData.icons" :key="index">
          <a :href="icon.link.url" target="blank">
            <img :alt="icon.image.alt" :src="icon.image.url" />
            <p v-html="icon.image_title[0].text"></p>
            <p v-if="getBannerItemDescription(icon)" class="description" v-html="getBannerItemDescription(icon)"></p>
          </a>
        </li>
      </ul>
      <div class="Serch-button">
        <a @click="pushDataLayerGoogle" title="Start your search here" href="https://www.google.com/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0514 9.17874L13.5586 12.6866C13.8315 12.9595 13.8324 13.4011 13.5564 13.6772C13.2823 13.9514 12.8339 13.9474 12.566 13.6795L9.05876 10.1716C6.85588 11.8863 3.66946 11.7311 1.64466 9.70588C-0.548221 7.51259 -0.548221 3.95655 1.64466 1.76326C3.83755 -0.430037 7.39291 -0.430037 9.5858 1.76326C11.6106 3.78844 11.7658 6.97545 10.0514 9.17874ZM8.59315 8.71305C10.2378 7.06808 10.2378 4.40106 8.59315 2.75609C6.94849 1.11111 4.28197 1.11111 2.6373 2.75609C0.992642 4.40106 0.992642 7.06808 2.6373 8.71305C4.28197 10.358 6.94849 10.358 8.59315 8.71305Z" fill="#252836"/>
          </svg>
          <span v-html="buttonSearchText()"></span>
        </a>
      </div>
    </article>

    <!-- Quick Links -->
    <article class="quick-link" v-if="quicklinksData.fullyLoaded && quicklinksData.title && quicklinksData.items.length > 0">

      <h3>{{ quicklinksData.title }}</h3>

      <ul class="ul-quick-links">
        <li v-for="(item, index) in quicklinksData.items" :key="index">

          <a :title="getQuicklinkTitle(item)" :href="getQuicklinkURL(item)" :target="getQuicklinkIfTargetBlank(item)">
          
            <img :src="getQuicklinkImage(item)" :alt="getQuicklinkImageAlt(item)"/>
            <p v-html="getQuicklinkTitle(item)"></p>
          
          </a>

        </li>
      </ul>

    </article>

    <!-- CINTILLO -->
    <article v-if="cintilloData.isAvailable" class="ribbon">
      <div class="wrapper">
        <div class="ribbon-info">
          <h2>{{ cintilloData.title }}</h2>
          <p>{{ cintilloData.subtitle }}</p>
        </div>
        <div v-if="cintilloData.CTAUrl && cintilloData.CTAText" class="ribbon-cta">
          <a :href="cintilloData.CTAUrl" target="_blank">{{ cintilloData.CTAText }}</a>
        </div>
      </div>  
    </article>

    <!-- NEWS -->
    <article v-if="newsDataHasItems" class="latest-news">
      <div class="wrapper">
        <h2>
          {{ prismicLang === 'en-us' ? "Latest news" : 'Últimas noticias' }}
        </h2>

        <!-- NEWS Repeater -->
        <div v-for="item in newsData" :key="item.uid">
          <div class="news-item">
            <div class="image-news-item">
              <img :src="item.data.news_image.url" :alt="item.data.news_image.alt">
            </div>
            <div class="info-news-item">
              <h3>{{ item.data.news_title[0].text }}</h3>
              <p>{{ item.data.news_body[0].text }}</p>
              <a v-if="item.data.news_link.url" :href="item.data.news_link.url" target="_blank">
                {{ prismicLang === 'en-us' ? "More Info" : 'Más Info' }}
              </a>
            </div>
          </div>
        </div>
        <div>
          <infinite-loading v-if="newsData" @infinite="infiniteHandler">
            <span slot="no-more">{{ prismicLang === 'en-us' ? "There is no more news." : 'No hay más noticias.' }}</span>
            <span slot="no-results">{{ prismicLang === 'en-us' ? "No more results." : 'No hay más resultados.' }}</span>
            <span slot="error">{{ prismicLang === 'en-us' ? "Error loading more news." : 'Error al cargar más noticias.' }}</span>
          </infinite-loading>
        </div>
      </div>
    </article>

  </section>

</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import Const from '../constants';
export default {  
  name: 'CLCHomePage',
  components: { InfiniteLoading },

  data(){
    return {
      prismicLang: '',
      //raw data
      homePageDocument: null,
      newsDocument: null,
      //cleaned up data
      homePageData: null, 
      newsData: null,
      
      //infinite plugin
      currentPage: 1,

      cintilloData: {
        title:'',
        subtitle:'',
        CTAText: '',
        CTAUrl: '',
        isAvailable: false,
      },

      bannerData: {
        title: '',
        description: '',
        icons: [],
      },

      quicklinksData:{
        fullyLoaded: false,
        title: '',
        items: [],
      },

      clcSegmentValue:'',// delete this default value once we start using the real url format

    }
  },

  methods: {
    newsDataHasItems(){
      return this.newsData?.length > 0;
    },
    setLanguage() {
      if (this.$route.path.startsWith('/es') || this.$route.path.startsWith('/en')) {
        this.prismicLang = this.$route.path.startsWith('/es') ? 'es-es' : 'en-us';
        return;
      }

      // Forzamos idioma cuando no contamos con un idioma determinado en la URL
      const browserLang = navigator.language || navigator.userLanguage; 
      let path = this.$route.path;
      let query = this.$route.query;

      const preferredLang = browserLang.startsWith('es') ? 'es' : 'en';
      const newPath = `/${preferredLang}${path}`;

      if (newPath !== path) {
        this.$router.replace({ path: newPath, query });
      }

      this.prismicLang = preferredLang === 'es' ? 'es-es' : 'en-us';
    },

    updateMeta() {
      const title = this.prismicLang.startsWith('en') ? 'Connected Learning Centers' : 'Centros de aprendizaje conectados';
      const content = this.prismicLang.startsWith('en') ? 'Welcome to your connected learning experience!' : '¡Te damos la bienvenida al aprendizaje online!';
      const meta = document.querySelector('meta[name="description"]');
      if (meta) {
        meta.setAttribute('content', content);
      } else {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'description');
        metaTag.setAttribute('content', content);
        document.head.appendChild(metaTag);
      }

      document.title = title;
    },

    buttonSearchText() {
      if (this.prismicLang === 'en-us') {
        return '<strong>Start your Internet search here</strong>';
      } else if (this.prismicLang === 'es-es') {
        return '<strong>Comienza tu búsqueda en Internet aquí</strong>';
      }
      return '';
    },

    async toggleLanguage() {

      this.sendSwitchLanguage();

      const currentQuery = this.$route.query;

      if (this.prismicLang === 'en-us') {
        // Cambia la URL manteniendo los parámetros
        this.$router.push({ path: '/es/', query: currentQuery });
        this.prismicLang = 'es-es';
      }else {
        this.$router.push({ path: '/en/', query: currentQuery });
        this.prismicLang = 'en-us';
      }

      this.updateMeta();
      await this.retrieveData();
      this.sendPageView();
    },

    getCenterParam() {
      const urlParams = new URLSearchParams(window.location.search);
      this.clcSegmentValue = urlParams.get(Const.CENTER) ?? this.clcSegmentValue;
    },

    //#region HOME, CINTILLO & QUICKLINKS DATA MANAGEMENT
    async getHomePageData() {
      try {
        const response = await this.$prismic.client.getSingle('clc_homepage', {
          lang: this.prismicLang,
        });
        this.homePageDocument = response;
        this.homePageData = response?.data;

        if (this.homePageDocument) {
          this.getCintilloData();
        }
      } catch (error) {
        console.error("METHOD: homePageData ==> ", error);
      }
    },

    getBannerData(){
      const data = this.homePageData;
      this.bannerData = {
        title: data?.title?.[0]?.text ||  this.bannerData.title,
        icons: data?.categories
      };
    },
    //#region QUICKLINKS
    async getQuickLinksData(){
      if (this.homePageData) {
        this.quicklinksData = {
          fullyLoaded: true,
          title: this.homePageData.title_quicklinks[0]?.text ?? '',
          items: this.homePageData.quicklinks ?? []
        }
      }
    },
    
    getQuicklinkTitle(item){
      return item?.title_link?.[0]?.text || '';
    },

    getQuicklinkURL(item){
      return item?.link?.url || '';
    },

    getQuicklinkImage(item){
      let imageUrl = item?.image?.url || '';
      if (imageUrl.includes('?auto=compress,format')) {
        imageUrl = imageUrl.replace('?auto=compress,format', '');
      }
      return imageUrl;
    },

    getQuicklinkImageAlt(item){
      return item?.image?.alt || '';
    },

    getQuicklinkIfTargetBlank(item){
      return item?.link?.target ?? '';
    },
    //#endregion

    getCintilloData(){ 
      this.cintilloData= {
        title:'',
        subtitle:'',
        CTAText: '',
        CTAUrl: '',
        isAvailable: false,
      };
      const data = this.homePageData;

      const lookUpLocal = data?.cintillos_locales?.find(cintillo =>
        cintillo.segment_cintillo_local === this.clcSegmentValue &&
        cintillo.cintillo_local_on_off === true &&
        cintillo.titulo_cintillo_local?.[0]?.text
      );

      if (lookUpLocal) {
        this.cintilloData = {
          title: lookUpLocal?.titulo_cintillo_local?.[0]?.text,
          subtitle: lookUpLocal?.body_text?.[0]?.text,
          CTAText: lookUpLocal?.link_text?.[0]?.text,
          CTAUrl: lookUpLocal?.link?.url,
          isAvailable: true
        }        
      }else if (data.cintillo_general_on_off && data?.title?.[0]?.text) {
        this.cintilloData = {
          title: data.titulo_cintillo_general?.[0]?.text,
          subtitle: data?.default_body_text?.[0]?.text,
          CTAText: data?.default_link_text?.[0]?.text,
          CTAUrl: data?.default_link?.url,
          isAvailable: true
        }        
      }
      
    },
    //#endregion

    //#region NEWS DATA MANAGEMENT
    async getNewsData(){
      try {
        const response = await this.$prismic.client.query([
            this.$prismic.predicate.at(Const.DOCUMENT_TYPE, Const.CLC_NEWS),
            this.$prismic.predicate.any('my.clc_news.targetings.clc_segment', [Const.GLOBAL, this.clcSegmentValue])
          ],{
            page: this.currentPage,
            pageSize: 3,
            lang: this.prismicLang,
            orderings: '[document.last_publication_date desc]' // Ordenar por fecha de última publicación
        });

        this.newsDocument = response;
        this.newsData = response?.results;

        if (this.currentPage < response?.total_pages) {
          this.currentPage++;
        }
      } catch (error) {
        console.log("METHOD: getNewsData ==> ", error);
      }
    },

    async infiniteHandler($state){
      try {
        if (this.currentPage === 1) {
          $state.complete();
          return;
        }
        let currentNews = this.newsData;
        await this.getNewsData();

          this.newsData = [...currentNews, ...this.newsData];

        if (this.currentPage < this.newsDocument?.total_pages) {
          $state.loaded();
        } else{
          $state.complete();
        }

      } catch (error) {
        console.log("METHOD: infiniteHandler ==> ", error);
      }
    },
    async retrieveData(){
      this.currentPage = 1;
      this.homePageDocument = null;
      this.newsDocument = null;
      this.homePageData = null; 
      this.newsData = null;

      await this.getHomePageData();
      await this.getQuickLinksData();
      this.getNewsData();
      this.getBannerData();
    },

    //#endregion

    //#region Analytics
    sendPageView(){
      console.log("sendPageView");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event':'pageview',
        'language': this.prismicLang || 'unknown'
      });
    },

    sendSwitchLanguage(){
      console.log("sendSwitchLanguage");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event':'switch_language',
        'button_text': this.prismicLang === 'es-es' ? "Switch to English" : "Cambiar a Español"
      });
    },
    //#endregion

    pushDataLayerGoogle() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'google-button-search',
      });
    },

    getBannerItemDescription(icon){
      return icon?.description?.[0]?.text || '';
    },
  },
  mounted(){
    this.sendPageView();
  },

  async created(){
    this.setLanguage();
    this.updateMeta();
    this.getCenterParam();
    await this.retrieveData();
  },
}
</script>

<style lang="scss">
  @import "../assets/scss/app.scss";
</style>
